:root {
  --white: white;
  --black: black;
}

body {
  font-family: 'Arimo', 'Arial', sans-serif;
}

.w-layout-vflex {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.unwrapped-edition {
  display: block;
}

.wrapped-edition {
  display: none;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-layout-hflex {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

#banner {
  background-color: #f6c318;
  color: #552a85;
  text-align: center;
  padding: 10px;
  position: relative;
  font-size: 24pt;
  font-family: monospace
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }

  #banner {
    font-size: 20pt;
  }

  #close-banner {
    display: none;
  }
}


article {
  margin: 20px;
}

h1 {
  font-size: 128px;
  font-weight: 700;
}

p {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 18px;
}

li {
  font-size: 18px;
}

.utility-page-wrap {
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
}

.utility-page-content {
  text-align: center;
  flex-direction: column;
  width: 260px;
  display: flex;
}

.utility-page-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.container-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.flex-block-2 {
  justify-content: center;
  align-items: center;
  flex-flow: column;
  justify-content: space-around;
  padding-top: 0;
  display: flex;
  overflow-x: hidden;
}

.heading-3 {
  color: #f6c415;
  font-size: min(22vw, 280px);
  overflow-x: hidden;
}

.wrapped-compute {
  display: none;
  line-height: 1.0;
}

.heading-3.unwrapped-compute.compute-gradiant {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #f6c415, #f6c41533);
  -webkit-background-clip: text;
  background-clip: text;
}

.text-block {
  color: #fff;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 18px;
}

.container-3 {
  height: 20vh;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.heading-3 {
  padding-bottom: 8px;
}

.heading-4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 5vw;
}

.heading-5 {
  margin-top: 0;
  margin-bottom: 0;
}

.heading-6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 64px;
}

.paragraph {
  font-size: 24px;
}

.heading-7 {
  font-size: 64px;
}

.grid {
  padding-left: 16px;
  padding-right: 16px;
}

.heading-8 {
  color: #f6c415;
  text-align: left;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #f6c415, rgba(246, 196, 21, .3));
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.image {
  -webkit-text-fill-color: inherit;
  background-clip: border-box;
  border-radius: 50%;
  margin-bottom: 0;
  margin-left: 20px;
  margin-right: 10px;
}

.flex-block-3 {
  align-items: center;
  margin-bottom: 20px;
}

.text-block-2 {
  color: #fff;
  font-size: 18px;
}

@media screen and (max-width: 991px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .heading-8 {
    margin-bottom: 20px;
    font-size: 64px;
  }
}

@media screen and (max-width: 767px) {
  .compute-gradiant {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #f6c415, #f6c41533);
    -webkit-background-clip: text;
    background-clip: text;
  }

  .wrapped-compute {
    display: block;
  }

  .ais-Hits-item {
    margin-bottom: 1em;
    width: calc(50% - 1rem);
  }

  .unwrapped-compute {
    display: none;
  }

  .heading-8 {
    font-size: 64px;
  }

  .heading-3 {
    font-size: 28vw;
    min-height: calc(28vw * 2.1);
  }
}

@media screen and (max-width: 479px) {
  .unwrapped-edition {
    display: none;
  }

  .wrapped-edition {
    display: block;
  }

  .flex-block-2 {
    flex-flow: column;
    justify-content: space-around;
  }

  .heading-3 {
    font-size: 48vw;
  }

  .wrapped-compute {
    display: block;
  }

  .heading-3.wrapped-compute.compute-gradiant {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #f6c415, #f6c41533);
    -webkit-background-clip: text;
    background-clip: text;
    min-height: 48vw;
    overflow-y: hidden;
  }

  .ais-Hits-item {
    margin-bottom: 1em;
    width: 100%;
  }

  .unwrapped-compute {
    display: none;
  }

  .text-block {
    font-size: 6vw;
  }
}
